import {Component} from '@angular/core';
import {Toast} from 'ngx-toastr';

@Component({
    selector: 'app-button-toast',
    templateUrl: './button-toast.component.html',
    styles: [`:host {
        display: block;
    }`],
})
export class ButtonToastComponent extends Toast {
    onBtnClick(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        this.delayedHideToast();
    }
}
