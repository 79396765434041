<div *ngIf="toastPackage.title"
     class="toast-title">
    {{ toastPackage.title }}
</div>
<div *ngIf="toastPackage.message"
     class="toast-message">
    {{ toastPackage.message }}
</div>
<div *ngIf="toastPackage.config.payload.buttonText"
     class="d-flex justify-content-center mt-2">
    <button class="btn btn-sm btn-white"
            (click)="onBtnClick($event)">
        {{ toastPackage.config.payload.buttonText }}
    </button>
</div>

