import {Injectable} from '@angular/core';
import {ActiveToast, IndividualConfig, ToastrService} from 'ngx-toastr';
import {filter, take, takeUntil} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {ButtonToastComponent} from '../portal/@portal-theme/components/button-toast/button-toast.component';

export type ToastTypes = 'success' | 'warning' | 'error' | 'info';
type ToastOptions = Partial<IndividualConfig>;

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    static toastManager: ToastrService;
    static router: Router;

    constructor(toastManager: ToastrService,
                router: Router) {
        ToastService.toastManager = toastManager;
        ToastService.router = router;
    }

    static success(description: string, title?: string, options?: ToastOptions): ActiveToast<any> {
        return this.toastManager.success(description, title, options);
    }

    static warning(description: string, title?: string, options?: ToastOptions): ActiveToast<any> {
        return this.toastManager.warning(description, title, options);
    }

    static error(description: string, title?: string, options?: ToastOptions): ActiveToast<any> {
        return this.toastManager.error(description, title, options);
    }

    static info(description: string, title?: string, options?: ToastOptions): ActiveToast<any> {
        return this.toastManager.info(description, title, options);
    }

    static clear(toastId?: number) {
        return this.toastManager.clear(toastId);
    }

    static buttonToast(type: ToastTypes, toastClickCallback: () => void, buttonClickCallback: () => void, description: string, buttonText: string, title?: string, options: ToastOptions = {}): ActiveToast<any> {
        const toast = this.toastManager[type](
            description,
            title,
            {
                messageClass: 'cursor-pointer',
                toastComponent: ButtonToastComponent,
                payload: {
                    buttonText,
                },
                ...options,
            }
        );

        toast.onAction.pipe(
            take(1),
            takeUntil(toast.onHidden)
        ).subscribe(() => {
            if (buttonClickCallback) buttonClickCallback();
        });

        toast.onTap.pipe(
            take(1),
            takeUntil(toast.onHidden)
        ).subscribe(() => {
            if (toastClickCallback) toastClickCallback();
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            takeUntil(toast.onHidden),
            take(1),
        ).subscribe(() => {
            this.clear(toast.toastId);
        });

        return toast;
    }

    success(description: string, title?: string, options?: ToastOptions) {
        return ToastService.success(description, title, options);
    }

    warning(description: string, title?: string, options?: ToastOptions) {
        return ToastService.warning(description, title, options);
    }

    error(description: string, title?: string, options?: ToastOptions) {
        return ToastService.error(description, title, options);
    }

    info(description: string, title?: string, options?: ToastOptions) {
        return ToastService.info(description, title, options);
    }

    clear(toastId?: number) {
        ToastService.clear(toastId);
    }

    buttonToast(type: ToastTypes, buttonClickCallback: () => void, description: string, buttonText: string, title?: string, options: ToastOptions = {}, toastClickCallback?: () => void) {
        return ToastService.buttonToast(type, toastClickCallback, buttonClickCallback, description, buttonText, title, options);
    }
}
